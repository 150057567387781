<template>
  <div>
    <v-container>
      <h1 style="margin-bottom: 60px">
        Finanzen
      </h1>

      <h2 class="mb-4">
        Nach einer Zahlung suchen:
      </h2>
      <v-text-field
        v-model="searchStr"
        outlined
        label="E-Mail-Adresse oder Page_ID eingeben"
        append-icon="mdi-magnify"
        @click:append="search"
      ></v-text-field>

      <!-- Buttons -->
      <v-row>
        <dashboard-btn target="Accounting" icon="nachweis" cols="3" v-if="user.accessRoles.finance.accounting">Rechnungslegung</dashboard-btn>
        <dashboard-btn target="Controlling" icon="zahlung" cols="3" v-if="user.accessRoles.finance.controlling">Controlling</dashboard-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardBtn from '@/components/dashboard/DashboardBtn.vue'
export default {
  data () {
    return {
      searchStr: ''
    }
  },
  methods: {
    search () {
      // TODO
      // search for this.searchStr
    }
  },
  computed: {
    investor () {
      return this.$store.getters.investor
    },
    user () {
      return this.$store.getters.user
    }
  },
  components: {
    dashboardBtn: DashboardBtn
  }
}
</script>

<style scoped>
* >>> .v-responsive__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center
}

.dashboard-btn {
  width: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 0px 15px #3f3f3f2e !important
}
</style>
