<template>
  <v-col :cols="cols">
    <v-card class="dashboard-btn" @click="navigateTo()">
      <v-responsive :aspect-ratio="1/1">
        <v-card-text>
          <!-- Icon -->
          <div style="padding-bottom: 20px">
            <v-icon :size="$vuetify.breakpoint.xs ? '50' : '150'">${{icon}}</v-icon>
          </div>
          <div :class="{ headline: $vuetify.breakpoint.mdAndUp }">
            <slot></slot>
          </div>
        </v-card-text>
      </v-responsive>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: ['icon', 'target', 'cols'],
  methods: {
    navigateTo () {
      this.$router.push({ name: this.target })
    }
  }
}
</script>

<style scoped>
  * >>> .v-responsive__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center
  }

  .dashboard-btn {
    width: 100%;
    border-radius: 10px !important;
    box-shadow: 0px 0px 15px #3f3f3f2e !important
  }
</style>
